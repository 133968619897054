<template>
  <div class="home">
    <nav>
      <a @click="changeMan('Jian')">Jian</a> |
      <a @click="changeMan('Zhao')">Zhao</a>
    </nav>
    <div>{{name}}</div>
    <img class="main-img" :src="prefix + name + suffix" alt="/">
  </div>
</template>

<script>
// @ is an alias to /src
// import Jian from '@/components/Jian.vue'

export default {
  name: 'HomeView',
  components: {
  },
  data() {
    return {
      name: 'Jian',
      prefix: '/static/img/',
      suffix: '.jpg'
    }
  },
  methods: {
    changeMan(name) {
      this.name = name;
    }
  }
}
</script>

<style>
  nav {
    padding: 30px;
  }

  nav a {
    font-weight: bold;
    color: #2c3e50;
  }

  nav a.router-link-exact-active {
    color: #42b983;
  }

  .main-img {
    width: 200px;
  }
</style>
